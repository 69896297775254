import { json, LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunctionArgs,
  useLocation,
  useMatches,
  useRouteLoaderData
} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';
import { toast as showToast } from 'sonner';

import { GeneralErrorBoundary } from '~/components/error-boundary';
import { Toaster } from '~/components/ui/sonner';
import { TooltipProvider } from '~/components/ui/tooltip';
import { getPublicEnv } from '~/lib/env.server';
import { identifyUser, trackPageView } from '~/lib/events.client';
import { Toast, getToastFromRequest } from '~/lib/toast.server';

import tailwind from './tailwind.css?url';

// TODO: find a more explicit way to force revalidation when the response has toast headers
export function shouldRevalidate({
  actionStatus,
  defaultShouldRevalidate
}: ShouldRevalidateFunctionArgs) {
  if (actionStatus === 500) return true;
  return defaultShouldRevalidate;
}

export const links: LinksFunction = () => {
  return [
    { rel: 'stylesheet', href: tailwind },
    {
      rel: 'preload',
      href: '/fonts/inter.woff2',
      as: 'font',
      type: 'font/woff2',
      crossOrigin: 'anonymous'
    }
  ];
};

export async function loader({ request, context }: LoaderFunctionArgs) {
  const auth = await context.authService.readAuthFromRequest();
  if (auth) {
    Sentry.setUser({
      email: auth.user.email
    });
  }

  const { toast, setCookieHeader } = await getToastFromRequest(request);
  const headers: Record<string, string> = {};
  if (setCookieHeader) {
    headers['Set-Cookie'] = setCookieHeader;
  }

  return json(
    {
      ENV: getPublicEnv(),
      user: auth?.user,
      toast
    },
    {
      headers
    }
  );
}

export function useRootLoaderData() {
  const data = useRouteLoaderData<typeof loader>('root');
  if (!data) throw new Error('No data returned from loader');
  return data;
}

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRootLoaderData();
  const location = useLocation();

  useToast(data?.toast);

  useEffect(() => {
    // Note: this will only identify users that are logged in on initial page load
    if (data?.user) {
      identifyUser({
        email: data.user.email,
        firstName: data.user.firstName,
        lastName: data.user.lastName,
        id: data.user.id
      });
    }
  }, [data?.user]);

  useEffect(() => {
    trackPageView();
  }, [location]);

  return (
    <html lang="en" className="h-full antialiased">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (window.heap = window.heap || []),
              (heap.load = function (e, t) {
                (window.heap.appid = e), (window.heap.config = t = t || {});
                for (
                  var n = function (e) {
                      return function () {
                        heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                      };
                    },
                    p = [
                      'addEventProperties',
                      'addUserProperties',
                      'clearEventProperties',
                      'identify',
                      'resetIdentity',
                      'removeEventProperty',
                      'setEventProperties',
                      'track',
                      'unsetEventProperty',
                    ],
                    o = 0;
                  o < p.length;
                  o++
                )
                  heap[p[o]] = n(p[o]);
              });
              heap.load('${ENV.HEAP_APP_ID}');
            `
          }}
        />
      </head>
      <body className="h-full min-h-full overscroll-y-none">
        {children}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data?.ENV)}`
          }}
        />
        <Toaster />
        <ScrollRestoration />
        <Scripts />
        {['develop', 'production'].includes(ENV.ENVIRONMENT ?? '') && (
          <script
            type="text/javascript"
            async
            src={`https://cdn.heapanalytics.com/js/heap-${ENV.HEAP_APP_ID}.js`}
          />
        )}
        <GoogleAds />
        <HappyFoxChat />
      </body>
    </html>
  );
}

function GoogleAds() {
  if (ENV.ENVIRONMENT !== 'production') {
    return null;
  }

  return (
    <>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=AW-16676279358"
      ></script>

      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-16676279358');
          `
        }}
      />
    </>
  );
}

function HappyFoxChat() {
  const matches = useMatches();

  if (!['develop', 'production'].includes(ENV.ENVIRONMENT ?? '')) {
    return null;
  }

  // Disable on the Accuserve landing page
  if (matches.some((match) => match.pathname === '/accuserve')) {
    return null;
  }

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.HFCHAT_CONFIG = {
              EMBED_TOKEN: 'e7c1aa70-3231-11ef-8a0c-11e71e3a3557',
              ASSETS_URL: 'https://widget.happyfoxchat.com/v2/visitor',
              onload: function() {window.HappyFoxChat = this;}
            };`
        }}
      ></script>

      <script
        type="text/javascript"
        src="https://widget.happyfoxchat.com/v2/visitor/js/widget-loader.js"
        async
      ></script>
    </>
  );
}

function App() {
  return (
    <TooltipProvider delayDuration={500}>
      <Outlet />
    </TooltipProvider>
  );
}

export default Sentry.withSentry(App);

function useToast(toast?: Toast | null) {
  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        showToast[toast.type](toast.title, {
          id: toast.id,
          description: toast.description,
          duration: toast.duration
        });
      }, 0);
    }
  }, [toast]);
}

export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
